import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';

type LinkProps = {
  to: string;
  target?: '_blank';
  className?: string;
};

export const Link: FC<LinkProps> = ({ children, to, target, className }) => {
  const isInternalLink = /^\/(?!\/)/.test(to);

  const routerProps = isInternalLink
    ? { to, component: RouterLink }
    : { href: to };

  const newTabProps = !isInternalLink && target ? { rel: 'noopener' } : {};

  return (
    <MaterialLink className={className} {...routerProps} {...newTabProps}>
      {children}
    </MaterialLink>
  );
};
