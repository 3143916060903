import { CircularProgress } from '@material-ui/core';
import React, { createContext, FC, useEffect, useState } from 'react';
import {
  getOrGenerateUserDocument,
  HBUser,
  listenForUserChange,
} from '../db/user';
import { auth } from '../firebase';

export const UserContext = createContext<{ user: null | HBUser }>({
  user: null,
});

const UserProvider: FC = ({ children }) => {
  const [hasAuthenticated, setHasAuthenticated] = useState(false);
  const [user, setUser] = useState<{ user: null | HBUser }>({
    user: null,
  });

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      const userDoc = await getOrGenerateUserDocument(userAuth);
      setUser({ user: userDoc });
      setHasAuthenticated(true);
    });
  }, []);

  useEffect(() => {
    if (user.user?.uid) {
      // If user is logged in, listen for changes to user object in FB
      const ref = listenForUserChange(user.user.uid, (hbUser) => {
        setUser({ user: hbUser });
      });

      return () => {
        ref.off();
      };
    }
  }, [user.user?.uid]);

  return (
    <UserContext.Provider value={user}>
      {hasAuthenticated ? (
        children
      ) : (
        <div className="flex items-center justify-center h-screen v-screen flex-col">
          <CircularProgress size={50} color="primary" />
          <h1 className="text-base mt-2.5">Fetching nectar...</h1>
        </div>
      )}
    </UserContext.Provider>
  );
};

export default UserProvider;
