import { IconButton, Menu } from '@material-ui/core';
import classNames from 'classnames';
import {
  FC,
  Fragment,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  ABOUT_US_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
} from '../../../utils/constants/routes';
import { auth } from '../../../utils/firebase';
import { UserContext } from '../../../utils/providers/user-context';
import Button from '../../atoms/button/button';
import { HamburgerIcon } from '../../atoms/illustrations/hamburger/hamburger';
import { Logo } from '../../atoms/illustrations/logo/logo';
import './navbar.scss';
import { StyledMenuItem } from './styled-menu-item/styled-menu-item';

type NavBarProps = {
  className: string;
};

const NavBar: FC<NavBarProps> = ({ className }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const location = useLocation();

  // Toggle sticky menu on scroll
  const [isSticky, setSticky] = useState(false);
  const handleScroll = () => {
    window.scrollY > 1 ? setSticky(true) : setSticky(false);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const containerClass = classNames(
    {
      'sticky bg-gray-50 filter drop-shadow-md': isSticky,
    },
    'hb--navbar top-0 z-50',
    className,
  );

  // Handle toggling mobile menu
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClick: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOutClick = () => {
    auth.signOut();
    handleClose();
  };

  const navigateToAbout = () => {
    window.open(ABOUT_US_ROUTE, '_blank');
    handleClose();
  };

  const navigateToPath = (path: string) => {
    return () => {
      history.push(path);
      handleClose();
    };
  };

  const isSignUpPage = location.pathname === SIGN_UP_ROUTE;
  const isLoginPage = location.pathname === LOGIN_ROUTE;

  return (
    <nav className={containerClass}>
      <div className="container flex items-center justify-between">
        <Link to={HOME_ROUTE} className="flex">
          <Logo className="hb--navbar-logo-img" />
          <div className="hb--navbar-logo-text">Hummingbird</div>
        </Link>

        <div className="hidden md:block">
          {user ? (
            <Button onClick={onLogOutClick}>Log Out</Button>
          ) : (
            <Fragment>
              {!isSignUpPage && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={navigateToPath(SIGN_UP_ROUTE)}
                  className={!isSignUpPage && !isLoginPage ? 'mr-2.5' : ''}
                  setMinWidth
                >
                  Sign Up
                </Button>
              )}

              {!isLoginPage && (
                <Button
                  variant="outlined"
                  onClick={navigateToPath(LOGIN_ROUTE)}
                  setMinWidth
                >
                  Log In
                </Button>
              )}
            </Fragment>
          )}
        </div>

        <div className="md:hidden hb--navbar-mobile-menu">
          <IconButton
            aria-label={Boolean(anchorEl) ? 'Close menu' : 'Open menu'}
            aria-controls="mobile-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <HamburgerIcon showX={Boolean(anchorEl)} />
          </IconButton>
          <Menu
            id="mobile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            elevation={4}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <StyledMenuItem onClick={navigateToAbout}>About</StyledMenuItem>
            {user ? (
              <StyledMenuItem onClick={onLogOutClick}>Logout</StyledMenuItem>
            ) : (
              <div>
                {!isSignUpPage && (
                  <StyledMenuItem onClick={navigateToPath(SIGN_UP_ROUTE)}>
                    Sign Up
                  </StyledMenuItem>
                )}
                {!isLoginPage && (
                  <StyledMenuItem onClick={navigateToPath(LOGIN_ROUTE)}>
                    Log In
                  </StyledMenuItem>
                )}
              </div>
            )}
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
