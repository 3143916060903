import React, { FC } from 'react';

type CheckIllustrationType = {
  className?: string;
  height?: string;
  width?: string;
};

export const CheckIllustration: FC<CheckIllustrationType> = ({
  className,
  width,
  height,
}) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 23"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={
        /**
         * Workaround svg height and width attributes not supporting rems in Firefox and IE by passing it through style
         * https://www.w3.org/TR/SVG11/types.html#DataTypeLength
         * */
        {
          width,
          height,
        }
      }
    >
      <path
        d="m11.5006 23c-.6751.0007-1.3226-.2673-1.79915-.7445l-8.997312-8.9805c-.954675-.9983-.936335-2.5746.041306-3.55044.977646-.97582 2.556966-.99412 3.557046-.04123l7.19811 7.18467 16.1969-16.165177c1.0001-.952892 2.5794-.934586 3.5571.041229.9776.975818.9959 2.552188.0413 3.550398l-17.9947 17.96105c-.477.4775-1.125.7454-1.8006.7445z"
        fill="#6f82fe"
      />
    </svg>
  );
};

CheckIllustration.defaultProps = {
  height: '1.4375rem',
  width: '2rem',
};
