import { MenuItem, withStyles } from '@material-ui/core';

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: '10.75rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#41415a',
    display: 'flex',
    flexFlow: 'row nowrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1rem 2rem',
    '&:focus, &:hover': {
      fontWeight: 700,
    },
  },
}))(MenuItem);

export default StyledMenuItem;
