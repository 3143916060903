import { CheckIcon, DuplicateIcon } from '@heroicons/react/solid';
import { Card, CardActions, CardContent } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '../../components/atoms/button/button';
import { Link } from '../../components/atoms/link/link';
import { createWorkoutSession } from '../../utils/db/workoutSession';
import { clearAllRefs, db, firebase } from '../../utils/firebase';
import { UserContext } from '../../utils/providers/user-context';
import { ProgressBar } from './progress-bar/progress-bar';
import Step from './step/step';

const generateMessageText = (
  sessionCode: string,
  sessionCodeLink: string | null,
) => {
  return `Hello, are you down for a live workout session? Use the link or session code below to connect & begin the workout! Let's go!

${sessionCodeLink || ''}
Session Code: ${sessionCode}`;
};

export const Home: FC = () => {
  const { user } = useContext(UserContext);
  const [sessionCode, setSessionCode] = useState<string | null>(null);
  const [hasTraineeJoined, setHasTraineeJoined] = useState(false);
  const [isStreamingSession, setIsStreamingSession] = useState(false);
  const [isInviteCopied, setIsInviteCopied] = useState(false);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [activeSessionRefs, setActiveSessionRefs] = useState<
    firebase.database.Reference[]
  >([]);
  const sessionCodeLink = `https://hummingbird.fit/join-session?code=${sessionCode}`;

  useEffect(() => {
    clearAllRefs(activeSessionRefs);

    if (user?.activeSessionId) {
      // Has an active session, look for members and session code
      const sessionCodeRef = db.ref(
        `/sessionMetadata/${user.activeSessionId}/sessionCode`,
      );
      sessionCodeRef.on('value', (snapshot) => {
        setSessionCode(snapshot.val());

        if (isCreatingSession && snapshot.val() !== null) {
          setIsCreatingSession(false);
        }
      });

      const sessionMemberRef = db.ref(
        `/sessionMembers/${user.activeSessionId}`,
      );
      sessionMemberRef.on('child_added', (snapshot) => {
        setHasTraineeJoined(true);
      });

      const startedAtRef = db.ref(
        `/sessionMetadata/${user.activeSessionId}/startedAt`,
      );
      startedAtRef.on('value', (snapshot) => {
        setIsStreamingSession(snapshot.val() !== null);
      });

      setActiveSessionRefs([sessionCodeRef, sessionMemberRef, startedAtRef]);
    } else {
      setHasTraineeJoined(false);
      setIsCreatingSession(false);
      setIsStreamingSession(false);
    }

    return () => {
      console.log(activeSessionRefs);
      clearAllRefs(activeSessionRefs);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.activeSessionId]);

  // Redirect if user is not authenticated
  if (!user) {
    return <Redirect to="/log-in" />;
  } else if (user.activeSessionId && hasTraineeJoined && isStreamingSession) {
    // If there's an active class with a trainee navigate to class page
    return <Redirect to="/session" />;
  }

  const createSession = () => {
    setIsCreatingSession(true);

    createWorkoutSession(user.uid);
  };

  const onCopyClick = () => {
    if (sessionCode) {
      navigator.clipboard.writeText(
        generateMessageText(sessionCode, sessionCodeLink),
      );
      setIsInviteCopied(true);

      setTimeout(() => {
        setIsInviteCopied(false);
      }, 3000);
    }
  };

  const nameCopy = user.firstName ? ` ${user.firstName}` : '';

  const isOnStep2 =
    Boolean(user.activeSessionId) && sessionCode && !isCreatingSession;
  const isOnStep3 = isOnStep2 && hasTraineeJoined;
  const stepNum = isOnStep3 ? 3 : isOnStep2 ? 2 : 1;

  return (
    <div className="container pb-20 sm:pt-20 flex flex-col justify-center items-center">
      {stepNum === 1 ? (
        <Step num={1}>
          <p className="max-w-md text-lg leading-loose">
            Hi{nameCopy}, are you ready for another great workout? Create a
            session to share with your trainee.
          </p>

          <Button
            variant="contained"
            color="primary"
            onClick={createSession}
            className="mt-10"
            isLoading={isCreatingSession}
            setMinWidth
          >
            Create session
          </Button>
        </Step>
      ) : stepNum === 2 ? (
        <Step num={2}>
          <p className="max-w-lg text-lg leading-loose">
            Almost there! Just share the invite below with your trainee.
          </p>

          <Card
            elevation={2}
            className="px-6 pt-4 pb-2 rounded-3xl max-w-lg my-6"
          >
            <CardContent>
              <p className="text-base mb-12">
                Hello, are you down for a live workout session? Open the link or
                enter the session code in the Hummingbird app to connect & begin
                the workout! Let’s go!
              </p>

              {sessionCodeLink && (
                <Link to={sessionCodeLink} className="font-bold">
                  {sessionCodeLink}
                </Link>
              )}
              <p className="mt-4 text-base">
                <b>Session code:</b> {sessionCode?.toUpperCase()}
              </p>
            </CardContent>
            <CardActions className="justify-end" disableSpacing>
              <Button
                size="small"
                startIcon={
                  isInviteCopied ? (
                    <CheckIcon className="text-primary-400 h-5 w-5" />
                  ) : (
                    <DuplicateIcon className="text-gray-500 h-5 w-5" />
                  )
                }
                disabled={isInviteCopied}
                onClick={onCopyClick}
              >
                {isInviteCopied ? 'Copied' : 'Copy'}
              </Button>
            </CardActions>
          </Card>

          <p className="max-w-lg text-lg leading-loose">
            You'll be notified when the trainee connects to the session
          </p>
        </Step>
      ) : stepNum === 3 ? (
        <Step num={3}>
          <p className="max-w-lg text-lg leading-loose">
            Your trainee has connected to the session! Just waiting for them to
            start the share from their Apple Watch...
          </p>
        </Step>
      ) : null}

      <div className="max-w-lg w-full mt-32">
        <ProgressBar currStep={stepNum} />
      </div>
    </div>
  );
};
