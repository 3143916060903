import { ClockIcon, FireIcon } from '@heroicons/react/outline';
import { Card } from '@material-ui/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Redirect, useHistory } from 'react-router';
import Button from '../../components/atoms/button/button';
import { CheckIllustration } from '../../components/atoms/illustrations/check/check';
import { cFunctions, db } from '../../utils/firebase';
import { UserContext } from '../../utils/providers/user-context';
import { HeartRateCard } from './heart-rate-card/heart-rate-card';

dayjs.extend(duration);

type SessionMetadata = {
  startedAt?: number;
  endedAt?: number;
  duration?: number;
};

/**
 * Breaks down a duration in seconds into hours, minutes, seconds
 * @param durationInSeconds - elasped time in seconds
 * @returns hour, minute, seconds breakdown
 */
const calculateElaspedTime = (
  durationInSeconds: SessionMetadata['duration'] | undefined,
) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (durationInSeconds) {
    let timeDiff = durationInSeconds;
    seconds = Math.round(timeDiff % 60);
    timeDiff = Math.floor(timeDiff / 60);
    minutes = Math.round(timeDiff % 60);
    hours = Math.floor(timeDiff / 60);
  }

  return { hours, minutes, seconds };
};

export const Session: FC = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [sessionId] = useState(user?.activeSessionId);
  const [isEndingSession, setIsEndingSession] = useState(false);
  const [healthMetrics, setHealthMetrics] = useState<{
    calories?: { timestamp: number; value: number }[];
    heartRate?: { timestamp: number; value: number }[];
  }>({});
  const [sessionMetadata, setSessionMetadata] = useState<SessionMetadata>();
  // Need to use ref to make it accessible inside the timeout
  const sessionMetadataRef = useRef(sessionMetadata);
  sessionMetadataRef.current = sessionMetadata;

  useEffect(() => {
    if (user && user.activeSessionId) {
      db.ref(`/sessionMetadata/${user.activeSessionId}`).on(
        'value',
        (snapshot) => {
          const data = snapshot.val();
          setSessionMetadata(data || {});
        },
      );

      db.ref(`/sessionData/${user.activeSessionId}`).on(
        'value',
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const traineeMetrics = Object.values(data)[0] as any;
            const heartRate = Object.values(traineeMetrics.heartRate || {}) as {
              timestamp: number;
              value: number;
            }[];

            setHealthMetrics({
              calories: Object.values(traineeMetrics.calories || {}) as {
                timestamp: number;
                value: number;
              }[],
              heartRate,
            });
          }
        },
        console.log,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect if user is not authenticated
  if (!user) {
    return <Redirect to="/log-in" />;
  } else if (!sessionId && !user.activeSessionId) {
    return <Redirect to="/home" />;
  }

  const { hours, minutes, seconds } = calculateElaspedTime(
    sessionMetadata?.duration,
  );

  const endSession = async () => {
    setIsEndingSession(true);

    await cFunctions.httpsCallable('endSessionOnCall')({
      sessionId: user.activeSessionId,
    });

    setIsEndingSession(false);
  };

  const title = sessionMetadata?.endedAt ? (
    <Fragment>
      Summary <CheckIllustration className="ml-2.5" />
    </Fragment>
  ) : (
    'Live session'
  );

  return (
    <div className="pb-20 sm:pt-20 container">
      <h1 className="text-5xl font-bold text-primary-400 flex items-center">
        {title}
      </h1>

      <div className="flex flex-wrap gap-8 auto-rows-max mt-12">
        <Card
          elevation={2}
          className="px-6 py-3 rounded-3xl h-36 w-36 flex flex-col items-center justify-between"
        >
          <h2 className="text-lg flex items-center text-center">
            <ClockIcon className="h-5 w-5 inline-block mr-1" />
            Duration
          </h2>
          <p className="hb--heading-2 font-bold">
            {hours < 10 ? '0' : ''}
            {hours}:{minutes < 10 ? '0' : ''}
            {minutes}:{seconds < 10 ? '0' : ''}
            {seconds}
          </p>
          <div />
        </Card>
        <Card
          elevation={2}
          className="px-6 py-3 rounded-3xl h-36 w-36 flex flex-col items-center justify-between"
        >
          <h2 className="text-lg flex items-center text-center">
            <FireIcon className="h-5 w-5 inline-block mr-1" />
            Calories
          </h2>
          <p className="hb--heading-2 font-bold">
            {healthMetrics.calories && healthMetrics.calories.length > 0
              ? healthMetrics.calories[healthMetrics.calories.length - 1].value
              : '-'}
          </p>
          <div />
        </Card>
        <HeartRateCard
          heartRates={healthMetrics.heartRate}
          endedAt={sessionMetadata?.endedAt}
        />
      </div>
      {!sessionMetadata?.endedAt && (
        <Button
          variant="contained"
          color="primary"
          className="mt-6"
          onClick={endSession}
          setMinWidth
          isLoading={isEndingSession}
        >
          End Session
        </Button>
      )}
      {sessionMetadata?.endedAt && (
        <Button
          variant="contained"
          color="primary"
          className="mt-6"
          onClick={() => history.push('/home')}
          setMinWidth
        >
          Create New Session
        </Button>
      )}
    </div>
  );
};
