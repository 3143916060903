import classnames from 'classnames';
import React, { FC } from 'react';

type StepCircleProps = {
  isComplete?: Boolean;
  className?: string;
};

export const StepCircle: FC<StepCircleProps> = ({ isComplete, className }) => {
  const containerClasses = classnames(
    {
      'bg-primary-400': isComplete,
      'border-gray-300 border-2 bg-white': !isComplete,
    },
    'w-7 h-7 rounded-full absolute',
    className,
  );

  return <div className={containerClasses} />;
};
