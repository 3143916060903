import classNames from 'classnames';
import firebase from 'firebase';
import React, { FC, useContext, useEffect, useState } from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { Redirect } from 'react-router-dom';
import Runner from '../../assets/illustrations/runner.svg';
import WorkoutDuo from '../../assets/illustrations/workout-duo.svg';
import { HOME_ROUTE } from '../../utils/constants/routes';
import { auth } from '../../utils/firebase';
import { UserContext } from '../../utils/providers/user-context';
import './auth.scss';

type AuthProps = {
  type: 'signUp' | 'logIn';
};

const copy = {
  signUp: {
    header: `Let's get back to it.`,
    button: 'Sign up',
    body: `Create an account and start tracking progress right away.`,
    image: WorkoutDuo,
  },
  logIn: {
    header: `Let's get started.`,
    button: 'Log in',
    body: `Log in with your existing account to start tracking progress.`,
    image: Runner,
  },
};

const Auth: FC<AuthProps> = ({ type }) => {
  const { user } = useContext(UserContext);
  const [currType, setCurrType] = useState<AuthProps['type']>(); // Used to force a unmount and mount of the firebase auth component so that the copy changes

  useEffect(() => {
    if (currType !== type) {
      setCurrType(type);
    }
  }, [type, currType]);

  // If already logged in, navigate to home
  if (user) {
    return <Redirect to={HOME_ROUTE} />;
  }

  const currCopy = copy[type];

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: `${currCopy.button} with Email`,
        requireDisplayName: false,
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: `${currCopy.button} with Google`,
      },
    ],
  };

  const containerClass = classNames([
    'hb--auth container pb-20 sm:pt-20 grid md:grid-cols-2',
    {
      'hb--auth--sign-up': type === 'signUp',
      'hb--auth--log-in': type === 'logIn',
    },
  ]);

  return (
    <div className={containerClass}>
      <div>
        <h1 className="font-medium text-3xl md:text-4xl mb-4">
          {currCopy.header}
        </h1>
        <p className="hb--body hb--color-secondary mb-10">{currCopy.body}</p>
        {currType === type && (
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        )}
      </div>

      <div className="flex justify-center mt-10 sm:mt-0">
        <img
          className="max-w-xs"
          src={currCopy.image}
          alt="Male and female workout trainer"
        />
      </div>
    </div>
  );
};

export default Auth;
