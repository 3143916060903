import { db, firebase } from '../firebase';
import { setUserActiveSessionId } from './user';

export const createWorkoutSession = (uid: string) => {
  const currDate = new Date();

  // Init class
  const classMetadataRef = db.ref('/sessionMetadata');
  const newClassRef = classMetadataRef.push();

  if (!newClassRef.key) {
    throw Error('Something went wrong creating the session');
  }
  newClassRef.set({
    createdBy: uid,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    title: `Workout - ${
      currDate.getMonth() + 1
    }/${currDate.getDate()}/${currDate.getFullYear()}`,
    startTime: null,
    endTime: null,
  });

  setUserActiveSessionId(uid, newClassRef.key);

  return newClassRef.key;
};
