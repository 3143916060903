import React, { FC } from 'react';

type LogoType = {
  className?: string;
  height?: string;
  width?: string;
};

export const Logo: FC<LogoType> = ({ className, width, height }) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 44 44"
      className={className}
      style={
        /**
         * Workaround svg height and width attributes not supporting rems in Firefox and IE by passing it through style
         * https://www.w3.org/TR/SVG11/types.html#DataTypeLength
         * */
        {
          width,
          height,
        }
      }
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0h44v44h-44z" fill="#fff" />
      <path
        d="m23.0829 12.886c-1.5464 4.2481-1.4126 8.6931.0458 12.6238 3.6437-2.0735 6.6033-5.3927 8.1499-9.6408 1.546-4.2483 1.4124-8.69325-.0461-12.62374-3.6437 2.07358-6.6035 5.39237-8.1496 9.64074z"
        fill="#708fff"
      />
      <path
        d="m15.6296 13.9673h-12.06272c-.37041 0-.67015-.2997-.67015-.6702 0-.3704.29974-.6701.67015-.6701h12.06272c.3704 0 .6702.2997.6702.6701 0 .3705-.2998.6702-.6702.6702z"
        fill="#ee8585"
      />
      <g fill="#708fff">
        <path d="m29.6347 11.2188c-3.1966 3.1966-4.954 7.2817-5.2931 11.4604 4.1787-.3394 8.2635-2.0967 11.4602-5.2933 3.1969-3.1966 4.954-7.2817 5.2934-11.46049-4.1788.3394-8.2639 2.09651-11.4605 5.29339z" />
        <path d="m36.4043 40.7734c0-5.7019-1.7188-11.0012-4.6624-15.4135h-3.8681c3.5255 4.4123 5.5148 9.7116 5.5148 15.4135z" />
        <path d="m18.0535 14.6153c-1.1048 1.7553-1.7537 3.8263-1.7537 6.0536 0 6.292 5.1006 11.3926 11.3926 11.3926 2.4367 0 4.6895-.7715 6.5418-2.0742-2.9994-7.1219-8.8793-12.7311-16.1807-15.372z" />
        <path d="m10.9385 13.2972c0 2.5908 2.1003 4.6911 4.691 4.6911 2.5908 0 4.6911-2.1003 4.6911-4.6911s-2.1003-4.69106-4.6911-4.69106c-2.5907 0-4.691 2.10026-4.691 4.69106z" />
      </g>
      <path
        d="m13.6191 12.6271c0 .5551.4501 1.0052 1.0053 1.0052.5551 0 1.0052-.4501 1.0052-1.0052 0-.5552-.4501-1.0052-1.0052-1.0052-.5552 0-1.0053.45-1.0053 1.0052z"
        fill="#373737"
      />
    </svg>
  );
};

Logo.defaultProps = {
  height: '2.75rem',
  width: '2.75rem',
};
