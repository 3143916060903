import {
  Button as MDButton,
  ButtonProps as MDButtonProps,
  CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './button.scss';

export type ButtonProps = {
  className?: string;
  to?: string;
  isLoading?: boolean;
  setMinWidth?: boolean;
} & MDButtonProps;

const Button: FC<ButtonProps> = ({
  className,
  children,
  to,
  isLoading,
  setMinWidth,
  ...props
}) => {
  // if (to && onClick) {
  //   throw Error('Cannot have both to and onClick props');
  // }

  const containerClass = classNames(
    {
      'hb--button--min-width': setMinWidth,
    },
    // 'hb--button inline-flex items-center justify-center',
    'hb--button',
    className,
  );

  const routerProps = to ? { to, component: Link } : {};

  return (
    <MDButton
      {...props}
      {...routerProps}
      className={containerClass}
      disabled={props.disabled || isLoading}
    >
      {children}
      {isLoading && <CircularProgress size={24} className="absolute" />}
    </MDButton>
  );
};

export default Button;
