import {
  ABOUT_US_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SIGN_UP_ROUTE,
  TERMS_AND_CONDITONS_ROUTE,
} from '../../../utils/constants/routes';
import Button from '../../atoms/button/button';
import { Link } from '../../atoms/link/link';

export const Footer = () => {
  return (
    <footer className="hbm--main__footer bg-opacity-40 -mx-5 px-5 pt-14 pb-8 border-t">
      <div className="container">
        <div className="flex justify-between mb-4">
          <p>©2021 Hummingbird</p>
          <Button variant="outlined" setMinWidth to={SIGN_UP_ROUTE}>
            Sign Up
          </Button>
        </div>

        <hr />

        <div className="grid sm:grid-cols-2 mt-5 gap-4 items-center">
          <div className="grid grid-flow-col-dense gap-4 auto-cols-max">
            {/* <Link to="/how-it-works" className="font-bold">How it Works</Link> */}
            <Link to={ABOUT_US_ROUTE} className="font-bold">
              About Us
            </Link>
            <Link to={PRIVACY_POLICY_ROUTE} className="font-bold">
              Privacy
            </Link>
            <Link to={TERMS_AND_CONDITONS_ROUTE} className="font-bold">
              Terms
            </Link>
          </div>

          <div className="flex sm:justify-end">
            <a
              href="https://apps.apple.com/us/app/hummingbird-realtime-metrics/id1562006201?itsct=apps_box_badge&amp;itscg=30200"
              style={{
                display: 'inline-block',
                overflow: 'hidden',
                borderRadius: '13px',
                width: '150px',
                height: '50px',
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1621296000&h=e9b867f5b7f458fd7c86e8a146ae7493"
                alt="Download on the App Store"
                style={{ borderRadius: '13px', width: '150px', height: '50px' }}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
