import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserContext } from '../../../utils/providers/user-context';

const ProtectedRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useContext(UserContext)!;

  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/log-in',
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
