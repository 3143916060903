import { db, firebase } from '../firebase';

enum UserRole {
  trainer = 'trainer',
  trainee = 'trainee',
}

export type HBUser = {
  uid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  photoURL?: string;
  role: UserRole;
  activeSessionId?: string;
};

export const getOrGenerateUserDocument = async (
  user: firebase.User | null,
): Promise<HBUser | null> => {
  if (!user) {
    return null;
  }

  const userRef = db.ref(`users/${user.uid}`);
  const snapshot = await userRef.once('value');

  if (!snapshot.exists()) {
    // User doesn't exist in DB so add user details
    try {
      const hbUser = {
        email: user.email || undefined,
        role: UserRole.trainer,
      } as HBUser;

      // Add optional fields (undefined cannot be used when calling ref.update)
      const firstName = user.displayName?.substr(
        0,
        user.displayName.indexOf(' '),
      );
      if (firstName) {
        hbUser.firstName = firstName;
      }
      const lastName = user.displayName?.substr(
        user.displayName.indexOf(' ') + 1,
      );
      if (lastName) {
        hbUser.lastName = lastName;
      }
      const photoURL = user.photoURL || undefined;
      if (photoURL) {
        hbUser.photoURL = photoURL;
      }

      await userRef.update(hbUser);

      return { ...hbUser, uid: user.uid };
    } catch (error) {
      console.error('Error creating user document', error);
      throw error;
      // TODO: HANDLE ERROR CREATING USER DOCUMENT
    }
  } else {
    // User existed in DB, return user details
    const hbUser = { ...snapshot.val(), uid: user.uid } as HBUser;

    return hbUser;
  }
};

export const setUserActiveSessionId = (
  uid: string,
  activeSessionId: string,
) => {
  const userRef = db.ref(`users/${uid}/activeSessionId`);
  userRef.set(activeSessionId);
};

export const listenForUserChange = (
  uid: string,
  onChange: (user: HBUser) => void,
): firebase.database.Reference => {
  const userRef = db.ref(`users/${uid}`);
  userRef.on('value', (snapshot) => {
    const hbUser = { ...snapshot.val(), uid } as HBUser;
    onChange(hbUser);
  });

  return userRef;
};
