import { LinearProgress } from '@material-ui/core';
import React, { FC } from 'react';
import { StepCircle } from './step-circle/step-circle';

type ProgressBarType = {
  currStep: Number;
  numSteps?: Number;
};

export const ProgressBar: FC<ProgressBarType> = ({ currStep, numSteps }) => {
  const progress = currStep === 1 ? 0 : currStep === 2 ? 50 : 100;

  return (
    <div className="h-7 flex items-center relative">
      <LinearProgress
        variant="determinate"
        value={progress}
        color="primary"
        className="w-full"
      />
      <StepCircle
        className="transform -translate-x-1/2"
        isComplete={currStep >= 1}
      />
      <StepCircle
        isComplete={currStep >= 2}
        className="left-1/2 transform -translate-x-1/2"
      />
      <StepCircle
        isComplete={currStep >= 3}
        className="left-full transform -translate-x-1/2"
      />
    </div>
  );
};
