import React, { forwardRef } from 'react';

type StepProps = {
  num: number;
};

const Step = forwardRef<HTMLDivElement, StepProps & { children: any }>(
  ({ num, children }, ref) => {
    return (
      <div className="flex flex-col text-center items-center" ref={ref}>
        <h2 className="mb-10 text-7xl font-bold text-primary-400">0{num}</h2>
        {children}
      </div>
    );
  },
);

export default Step;
