import { createMuiTheme } from '@material-ui/core/styles';
import shadows, { Shadows } from '@material-ui/core/styles/shadows';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import NavBar from './components/molecules/navbar/navbar';
import ProtectedRoute from './components/molecules/protected-route/protected-route';
import Auth from './pages/auth/auth';
import { Session } from './pages/session/session';
import { Home } from './pages/home/home';
import UserProvider from './utils/providers/user-context';
import { Footer } from './components/molecules/footer/footer';

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#708fff',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#fff',
      },
      // secondary: {
      //   light: '#0066ff',
      //   main: '#0044ff',
      //   // dark: will be calculated from palette.secondary.main,
      //   contrastText: '#ffcc00',
      // },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    shadows: [
      'none',
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      '0px 5px 24px rgba(81, 81, 81, 0.15)',
      '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
      '0px 2px 15px rgb(81, 81, 81, 0.50)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      '0px 5px 30px rgb(81, 81, 81, 0.95)',
      ...shadows.slice(7),
    ] as Shadows,
    typography: {
      htmlFontSize: 16,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <div id="outer-container" className="hb--app">
          <Router>
            <div>
              <NavBar className="mb-8" />

              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <div id="page-wrap">
                <Switch>
                  <Route path="/sign-up">
                    <Auth type="signUp" />
                  </Route>
                  <Route path="/log-in">
                    <Auth type="logIn" />
                  </Route>
                  <ProtectedRoute path="/session">
                    <Session />
                  </ProtectedRoute>
                  <ProtectedRoute path="/">
                    <Home />
                  </ProtectedRoute>
                </Switch>
              </div>

              <Footer />
            </div>
          </Router>
        </div>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
