import { Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { HeartOutlineIllustration } from '../../../components/atoms/illustrations/heart-outline/heart-outline';

type HeartRateCardProps = {
  heartRates?: { timestamp: number; value: number }[];
  endedAt?: number;
};

const generateCompleteHeartRateDataPoints = (
  heartRateDataPoints: HeartRateCardProps['heartRates'],
  endedAt: HeartRateCardProps['endedAt'],
) => {
  if (!heartRateDataPoints) {
    return [];
  }

  let tempHeartRateData = [];

  for (let index = 0; index < heartRateDataPoints.length; index++) {
    const currHeartRate = heartRateDataPoints[index];

    if (index > 0 && index < heartRateDataPoints.length - 1) {
      // Fill in every second between the previous timestamp and the current timestamp

      const previousHeartRate = heartRateDataPoints[index - 1];
      const secondsDiff = dayjs(currHeartRate.timestamp).diff(
        previousHeartRate.timestamp,
        'seconds',
      );

      for (let subIndex = 1; subIndex <= secondsDiff; subIndex++) {
        tempHeartRateData.push({
          timestamp: dayjs(previousHeartRate.timestamp)
            .add(dayjs.duration({ seconds: subIndex }))
            .valueOf(),
          value: previousHeartRate.value,
        });
      }
    } else if (index === heartRateDataPoints.length - 1) {
      // Fill in every second between the current timestamp and now
      const secondsDiff = dayjs(endedAt).diff(
        currHeartRate.timestamp,
        'seconds',
      );

      for (let subIndex = 1; subIndex <= secondsDiff; subIndex++) {
        tempHeartRateData.push({
          timestamp: dayjs(currHeartRate.timestamp)
            .add(dayjs.duration({ seconds: subIndex }))
            .valueOf(),
          value: currHeartRate.value,
        });
      }
    }

    tempHeartRateData.push(currHeartRate);
  }

  return tempHeartRateData;
};

export const HeartRateCard: FC<HeartRateCardProps> = ({
  heartRates,
  endedAt,
}) => {
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout>();
  const [heartRateAvg, setHeartRateAvg] = useState<number>();
  const [heartRateMin, setHeartRateMin] = useState<number>();
  const [heartRateMax, setHeartRateMax] = useState<number>();
  const [heartRateData, setHeartRateData] =
    useState<{ timestamp: number; value: number }[]>();

  useEffect(() => {
    if (intervalRef) {
      clearInterval(intervalRef);
    }

    if (!endedAt) {
      // Update every second incase of missing info
      const interval = setInterval(() => {
        const tempHeartRateData = generateCompleteHeartRateDataPoints(
          heartRates,
          endedAt,
        );
        const heartRateStats = tempHeartRateData.reduce<{
          total: number;
          min: number;
          max: number;
        }>(
          (acc, c) => {
            return {
              total: acc.total + c.value,
              min: acc.min <= c.value ? acc.min : c.value,
              max: acc.max >= c.value ? acc.max : c.value,
            };
          },
          { total: 0, min: 1000000, max: 0 },
        );
        const tempHeartRateAvg = Math.round(
          heartRateStats.total / tempHeartRateData.length,
        );

        setHeartRateData(tempHeartRateData);
        setHeartRateAvg(tempHeartRateAvg);
        setHeartRateMax(heartRateStats.max);
        setHeartRateMin(heartRateStats.min);
      }, 1000);

      setIntervalRef(interval);

      return () => {
        clearInterval(interval);
      };
    }

    // TODO: Make sure ignoring isn't an issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heartRates, endedAt]);

  useEffect(() => {
    // Clear out interval ref when ended date exists
    if (intervalRef && endedAt) {
      clearInterval(intervalRef);
    }
  }, [intervalRef, endedAt]);

  let xAxisTicks = [];
  if (heartRateData && heartRateData.length > 0) {
    // Round down min time
    const minTime = dayjs(heartRateData[0].timestamp).set('second', 0);
    // Round up max time
    const maxTime = dayjs(heartRateData[heartRateData.length - 1].timestamp)
      .set('second', 0)
      .add(dayjs.duration({ minutes: 1 }));

    const numMinutes = Math.max(maxTime.diff(minTime, 'minute') + 1, 5);

    for (let index = 0; index <= numMinutes; index++) {
      const timestamp = minTime.add(dayjs.duration({ minutes: index }));
      xAxisTicks.push(timestamp.valueOf());
    }
  }

  return (
    <Card elevation={2} className="px-6 py-3 rounded-3xl h-72 w-full md:w-3/5">
      <div className="flex flex-col justify-between items-stretch h-full">
        <div className="flex justify-between -mt-2.5">
          <h2 className="text-lg flex items-center">
            <HeartOutlineIllustration className="h-5 w-5 inline-block mr-1" />
            Heart Rate (bpm)
          </h2>
          <div className="flex items-center">
            <h3 className="mr-4">Current</h3>
            <p className="hb--heading-2 font-bold">
              {heartRateData && heartRateData.length > 0
                ? heartRateData[heartRateData.length - 1].value
                : '-'}
            </p>
          </div>
        </div>
        {heartRateData && heartRateData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={heartRateData}
              margin={{
                top: 10,
                right: 0,
                left: -27,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="timestamp"
                domain={['auto', 'auto']}
                ticks={xAxisTicks}
                tickFormatter={(value, index) => {
                  return dayjs(value).format('h:mm');
                }}
                scale="time"
                type="number"
                minTickGap={10}
                interval="preserveStartEnd"
                tick={{ fontSize: '0.875rem' }}
              />
              <YAxis />
              <Tooltip
                formatter={(value: number) => [value, 'Heart Rate']}
                labelFormatter={(value, index) => {
                  return dayjs(value).format('h:mm:ss A');
                }}
                contentStyle={{ fontWeight: 700 }}
              />
              <Line
                type="monotone"
                stroke="#708fff"
                strokeWidth={3}
                dataKey="value"
                aria-label="Heart Rate"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Skeleton variant="rect" height={200} className="rounded" />
        )}

        <div className="flex justify-end">
          <div className="inline-flex items-center mr-6">
            <h3 className="mr-2 text-sm">Average</h3>
            <p className="hb--heading-2 font-bold text-base">
              {heartRateAvg || '-'}
            </p>
          </div>
          <div className="inline-flex items-center">
            <h3 className="mr-2 text-sm">Min & Max</h3>
            <p className="hb--heading-2 font-bold text-base">
              {/** TODO: Use const for heart rate min init value */}
              {heartRateMin !== 1000000 ? heartRateMin : '-'}/
              {heartRateMax || '-'}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};
