import React, { FC } from 'react';

type HeartOutlineIllustrationType = {
  className?: string;
};

export const HeartOutlineIllustration: FC<HeartOutlineIllustrationType> = ({
  className,
}) => {
  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 24 23"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke="#091133" strokeLinecap="square" strokeWidth="2">
        <path d="m1 9.99991h6.99972l2.00019-3.00028 4.00039 5.99967 2.0002-3.00031h6.9997" />
        <path d="m5 13.2c2.09697 2.8028 4.43947 5.4131 6.9997 7.8 2.5606-2.3869 4.9034-4.9972 7.0007-7.8" />
        <path d="m22 6.42946c-.0156-3.00213-2.453-5.42799-5.4551-5.42946-1.8257.01277-3.527.92726-4.5449 2.44294-1.0179-1.51568-2.71918-2.43017-4.54487-2.44294-3.00217.00147-5.4395 2.42733-5.45513 5.42946" />
      </g>
    </svg>
  );
};
